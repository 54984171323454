import { Component, Vue } from 'vue-property-decorator';
import Log from '../../activity/activity-ops-log/activity-ops-log.vue';

@Component({
  components: { Log }
})
class MerchantOpsLogPage extends Vue {
  title = 'merchant-ops-log';
}

export default MerchantOpsLogPage;
