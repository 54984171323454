<template>
  <div class="page-merchant-ops-log">
    <log/>
  </div>
</template>

<script lang="js">
import MerchantOpsLogPage from './merchant-ops-log';

export default MerchantOpsLogPage;
</script>

<style lang="scss">
@import "./merchant-ops-log";
</style>
